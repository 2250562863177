.gift-modal__image-col {
  max-width: 180px;
  max-height: 200px;
  justify-content: end !important;
}

.gift-modal__image {
  max-width: 100%;
  max-height: 200px;
}
