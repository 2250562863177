.success-action__button__row {
  justify-content: center !important;
}

.success-action__icon {
  font-size: 3rem !important;
  color: var(--color-primary-dark) !important;
}

.success-action__text {
  color: var(--color-primary-dark) !important;
}
