.gift-card-bloc {
  width: 250px;
}

.gift-card-bloc-image {
  max-width: 100%;
  max-height: 190px;
  align-self: center;
}

.gift-card-actions {
  justify-content: center;
}

.gift-card-bloc-image-height {
  height: 190px;
  justify-content: center;
  display: flex !important;
}
