.gift-card {
  width: 300px;
  border: 1px solid transparent;
  transition: 1s ease all;
}

.gift-card--new {
  border-color: orange;
  transition: 1s ease all;
}

.gift-card--disabled {
  border-color: darkred;
}

.gift-card__disabled__icon {
  color: darkred;
}

.gift-card .MuiCardHeader-avatar {
  color: orange;
}

.gift-card .MuiCardHeader-title {
  font-size: 1.25em;
}

.gift-bloc-status {
  min-height: 18px;
}
