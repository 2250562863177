.gifts__form-bloc__col .el-form-item {
  margin-bottom: 0;
}

.gift-bloc-link {
  font-size: 0.8em;
  color: #666;
}

.gift-modal .el-dialog__body {
  padding-bottom: 1em;
}

.gift-modal .el-dialog__title {
  font-size: 1.4em;
}

@media (max-width: 590px) {
  .gifts__form-bloc {
    flex-flow: column;
  }

  .gifts__form-bloc__col {
    margin-bottom: 5px;
  }

  .gifts__form-bloc__col__row {
    justify-content: center;
  }
}
