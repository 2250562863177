.loader {
  display: flex;
  margin: auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
  animation: animate 1.2s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
  filter: blur(3px);
}

.loader span:nth-child(2) {
  filter: blur(6px);
}

.loader span:nth-child(3) {
  filter: blur(12px);
}

.loader span:nth-child(4) {
  filter: blur(25px);
}

.loader:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #f1f1f1;
  border: solid white 10px;
  border-radius: 50%;
}
