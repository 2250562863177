.fail-action__button__row {
  justify-content: center !important;
}

.fail-action__icon {
  font-size: 3rem !important;
  color: var(--color-error) !important;
}

.fail-action__text {
  color: var(--color-error) !important;
}
