@import url(https://fonts.googleapis.com/css?family=Roboto:100,200,400,700,900);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
:root {
  --color-font: #233140 !important;
  --color-background: white;
  --color-primary: #7ed739;
  --color-primary-dark: #71b323;
  --color-error: #a30200;
  --margin-xs: 0.25em;
  --margin-s: 0.5em;
  --margin-m: 1em;
  --margin-l: 1.5em;
}

/* margin */
.m-n {
  margin: 0;
}

.m-l {
  margin: var(--margin-l);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.m-xs {
  margin: var(--margin-xs);
}


/* margin right */
.mr-l {
  margin-right: var(--margin-l);
}

.mr-m {
  margin-right: var(--margin-m);
}

.mr-s {
  margin-right: var(--margin-s);
}

.mr-xs {
  margin-right: var(--margin-xs);
}

.mr-n {
  margin-right: 0;
}

/* margin left */
.ml-l {
  margin-left: var(--margin-l);
}

.ml-m {
  margin-left: var(--margin-m);
}

.ml-s {
  margin-left: var(--margin-s);
}

.ml-xs {
  margin-left: var(--margin-xs);
}

.ml-n {
  margin-left: 0;
}

/* margin bottom */
.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-m {
  margin-bottom: var(--margin-m);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-n {
  margin-bottom: 0;
}

/* margin top */
.mt-l {
  margin-top: var(--margin-l);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-n {
  margin-top: 0;
}

.MuiButton-containedPrimary {
  color: var(--color-font) !important;
}

.MuiButton-textPrimary {
  color: var(--color-primary-dark) !important;
}
.MuiButton-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
.MuiAppBar-colorPrimary {
  background: white !important;
  color: var(--color-font) !important;
  border-bottom: 1px solid var(--color-primary);
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-font);
  background: var(--color-background);
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

a {
  color: var(--color-font);
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1 1;
}

.row {
  display: flex;
  flex-flow: row;
  width: 100%;
}

.col {
  display: flex;
  flex-flow: column;
}

.wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-between {
  justify-content: space-between !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.space-around {
  justify-content: space-around;
}

.flex-end {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
}

.center {
  text-align: center;
}

.justify {
  text-align: justify;
}

.full {
  width: 100%;
}

.closable-modal__title > h2 {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  line-height: 48px;
}

span.emoji {
  width: 19px;
  display: inline-block;
  margin-right: 4px;
}

.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

.scrollable-container {
  flex: 1 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  justify-content: center;
  outline: none;
}

.container {
  height: 100%;
  width: 98%;
  justify-content: center;
  margin: auto;
}

.gifts__form-bloc__col .el-form-item {
  margin-bottom: 0;
}

.gift-bloc-link {
  font-size: 0.8em;
  color: #666;
}

.gift-modal .el-dialog__body {
  padding-bottom: 1em;
}

.gift-modal .el-dialog__title {
  font-size: 1.4em;
}

@media (max-width: 590px) {
  .gifts__form-bloc {
    flex-flow: column;
  }

  .gifts__form-bloc__col {
    margin-bottom: 5px;
  }

  .gifts__form-bloc__col__row {
    justify-content: center;
  }
}

.gift-card-bloc {
  width: 250px;
}

.gift-card-bloc-image {
  max-width: 100%;
  max-height: 190px;
  align-self: center;
}

.gift-card-actions {
  justify-content: center;
}

.gift-card-bloc-image-height {
  height: 190px;
  justify-content: center;
  display: flex !important;
}

.gift-modal__image-col {
  max-width: 180px;
  max-height: 200px;
  justify-content: end !important;
}

.gift-modal__image {
  max-width: 100%;
  max-height: 200px;
}

.fail-action__button__row {
  justify-content: center !important;
}

.fail-action__icon {
  font-size: 3rem !important;
  color: var(--color-error) !important;
}

.fail-action__text {
  color: var(--color-error) !important;
}

.success-action__button__row {
  justify-content: center !important;
}

.success-action__icon {
  font-size: 3rem !important;
  color: var(--color-primary-dark) !important;
}

.success-action__text {
  color: var(--color-primary-dark) !important;
}

.loader {
  display: flex;
  margin: auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
  -webkit-animation: animate 1.2s linear infinite;
          animation: animate 1.2s linear infinite;
}

@-webkit-keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader span {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(#f07e6e, #84cdfa, #5ad1cd);
}

.loader span:nth-child(1) {
  -webkit-filter: blur(3px);
          filter: blur(3px);
}

.loader span:nth-child(2) {
  -webkit-filter: blur(6px);
          filter: blur(6px);
}

.loader span:nth-child(3) {
  -webkit-filter: blur(12px);
          filter: blur(12px);
}

.loader span:nth-child(4) {
  -webkit-filter: blur(25px);
          filter: blur(25px);
}

.loader:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  background: #f1f1f1;
  border: solid white 10px;
  border-radius: 50%;
}

.gift-card {
  width: 300px;
  border: 1px solid transparent;
  transition: 1s ease all;
}

.gift-card--new {
  border-color: orange;
  transition: 1s ease all;
}

.gift-card--disabled {
  border-color: darkred;
}

.gift-card__disabled__icon {
  color: darkred;
}

.gift-card .MuiCardHeader-avatar {
  color: orange;
}

.gift-card .MuiCardHeader-title {
  font-size: 1.25em;
}

.gift-bloc-status {
  min-height: 18px;
}

.new-gift__tooltip-icon {
  color: orange;
}

.gifts-stat-title {
  font-weight: bold;
  margin-right: 3px;
}

.gift-edit-form {
  max-width: 750px;
  margin: auto;
}

.gift-edit-form .el-form-item, .gift-edit-form .el-select {
  width: 100%;
}

.gift-edit-file-row-input {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.gift-edit-file-row {
  justify-content: flex-end;
}

.gift-edit__contribution__icon {
  transition: 1s ease all;
}

.gift-edit__contribution__icon:hover {
  color: var(--color-error);
  transition: 1s ease all;
}

.gift-edit-custom-label-col {
  width: 200px;
  min-width: 200px;
}

.gift-edit__contribution__row {
  min-height: 22px;
  padding-left: 2px;
  transition: 1s ease all;
}

.gift-edit__contribution__row:hover {
  background: #fafafa;
  transition: 1s ease all;
}

.information-modal-line__col {
  display: inherit;
}

.information-modal-line__row {
  display: none;
}
@media (max-width: 520px) {
  .information-modal-line__col {
    display: none;
  }

  .information-modal-line__row {
    display: inherit;
  }
}

