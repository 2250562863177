.gift-edit-form {
  max-width: 750px;
  margin: auto;
}

.gift-edit-form .el-form-item, .gift-edit-form .el-select {
  width: 100%;
}

.gift-edit-file-row-input {
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.gift-edit-file-row {
  justify-content: flex-end;
}

.gift-edit__contribution__icon {
  transition: 1s ease all;
}

.gift-edit__contribution__icon:hover {
  color: var(--color-error);
  transition: 1s ease all;
}

.gift-edit-custom-label-col {
  width: 200px;
  min-width: 200px;
}

.gift-edit__contribution__row {
  min-height: 22px;
  padding-left: 2px;
  transition: 1s ease all;
}

.gift-edit__contribution__row:hover {
  background: #fafafa;
  transition: 1s ease all;
}
