/* margin */
.m-n {
  margin: 0;
}

.m-l {
  margin: var(--margin-l);
}

.m-m {
  margin: var(--margin-m);
}

.m-s {
  margin: var(--margin-s);
}

.m-xs {
  margin: var(--margin-xs);
}


/* margin right */
.mr-l {
  margin-right: var(--margin-l);
}

.mr-m {
  margin-right: var(--margin-m);
}

.mr-s {
  margin-right: var(--margin-s);
}

.mr-xs {
  margin-right: var(--margin-xs);
}

.mr-n {
  margin-right: 0;
}

/* margin left */
.ml-l {
  margin-left: var(--margin-l);
}

.ml-m {
  margin-left: var(--margin-m);
}

.ml-s {
  margin-left: var(--margin-s);
}

.ml-xs {
  margin-left: var(--margin-xs);
}

.ml-n {
  margin-left: 0;
}

/* margin bottom */
.mb-l {
  margin-bottom: var(--margin-l);
}

.mb-m {
  margin-bottom: var(--margin-m);
}

.mb-s {
  margin-bottom: var(--margin-s);
}

.mb-xs {
  margin-bottom: var(--margin-xs);
}

.mb-n {
  margin-bottom: 0;
}

/* margin top */
.mt-l {
  margin-top: var(--margin-l);
}

.mt-m {
  margin-top: var(--margin-m);
}

.mt-s {
  margin-top: var(--margin-s);
}

.mt-xs {
  margin-top: var(--margin-xs);
}

.mt-n {
  margin-top: 0;
}
